<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
      <v-col
        cols="6"
        md="3"
      >
      <v-menu
        v-model="menuDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          readonly
          v-bind="attrs"
          v-on="on"

          dense
        outlined
        hide-details
        label="Період"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        type="month"
        locale="uk-UA"
        @input="menuDate = false"
      ></v-date-picker>
    </v-menu>
      
      </v-col>
      <v-col
          cols="6"
          md="3"
        >
        <v-select
          v-model="filterDistributorGroup"
          :items="distributorList"
          label="Постачальник"
          clearable
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          ></v-select>
        </v-col>
      
      <v-col
        cols="12"
        md="2"
        lg="1"
        class="text-center"
      >
        <v-btn color="primary" @click="getDistricts()"
        >
        
          Отримати
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="11" xl="10">
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема даних для відображення"
          hide-default-footer
          :items-per-page="-1"
          :items="districts"
          :options.sync="options"
          :loading="loading"
          class="elevation-1 pa-3 table-report-district"
          focusable
        >
          <template v-slot:top>

            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Дільниці</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>
              
              
            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ districts.indexOf(item) + 1 }}</span>
        </template>
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  export default {
    name: "report-districts",
    props: ["user"],
    data: () => ({
      filterOperatorGroup: null,
      filterDistributorGroup: null,
      distributorList: [],
      menuDate: false,
      updateTable: 0,
      districts: [],
      form_data: {},
      date: new Date().toISOString().substr(0, 7),
      loading: false,
      options: {},
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        { text: "Назва", value: 'name', sortable: false, },
        { text: "Абоненти", value: 'count', cellClass: 'text-end', class: 'text-end', sortable: false, },
        { text: "Відключені / Відпустка", value: 'count_disabled', cellClass: 'text-end', class: 'text-end', sortable: false, },
        { text: 'Сплатили', value: 'count_payment', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'Сума', value: 'sum', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: '%', value: 'percentage', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'Середній Платіж', value: 'avg', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'IPoE', value: 'IPoE', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'PPPoE', value: 'PPPoE', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'xPON', value: 'xPON', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
        { text: 'Real IP', value: 'real_ip', cellClass: 'text-end', class: 'text-end', sortable: false, }, 
      ],
    }),
    computed: {
        ...mapGetters({
          operators_groups: 'filters/operators_groups',
          operators: "filters/operators",
        }),
        computedDateFormatted () {
          return this.formatDate(this.date)
        },
    },
    mounted(){
      //console.log("mounted component reports districts")
      this.filterOperatorGroup = this.user.operator_id
      this.changeDistributorList()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler (val, oldVal) {
          oldVal.page && this.getDistricts();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getDistricts();
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month] = date.split('-')
        return `${this.monthFormat[Number(month-1)]} ${year}`
      },
      getDistricts(){
        this.loading = true
            
            axios({
                method: "post",
                url: this.$router.app.devUrlBillingAPI,
                data: {req_cmd: "reportDistricts", month: this.date.split('-')[1], year: this.date.split('-')[0], operator: this.filterDistributorGroup},
            })
                .then(response => {
                    if(response.data.resp_status == "OK"){
                        this.districts = response.data.resp_result.result
                    }else if(response.data.resp_errmsg){
                      this.districts = []
                      this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg);
                    }else{
                    this.districts = []
                    }
                    //console.log(response)
                })
                .catch(err => {
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err);
                })
                .finally(() => (this.loading = false))
      },
      changeDistributorList(){
        this.filterDistributorGroup = null
        if(!this.filterOperatorGroup) {
          return
        }
        let distributor = this.operators_groups.find(x => x.id == this.filterOperatorGroup)?.distributor
        this.distributorList = distributor.filter(x => x.inet) 
        this.filterDistributorGroup = this.distributorList[0] && this.distributorList[0].id || null
      }
    },
  }
</script>

<style >
.table-report-district tr:last-child:not(.v-data-table__empty-wrapper){
  font-weight: 800;
}
</style>