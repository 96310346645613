<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
        <v-col
          cols="6"
          md="3"
        >
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              readonly
              v-bind="attrs"
              v-on="on"

              dense
            outlined
            hide-details
            label="Період"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDate"
            type="month"
            locale="uk-UA"
            @input="menuDate = false"
          ></v-date-picker>
        </v-menu>
        </v-col>
        
        <v-col
        v-if="user && user.operator_id == 1"
          cols="6"
          md="3"
        >
        <v-select
        
          v-model="filterOperatorGroup"
          :items="operators_groups"
          label="Оператор"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          @input="changeDistributorList"
          ></v-select>
        </v-col>
        <v-col
        v-else
          cols="6"
          md="3"
        >
        <v-select
          v-model="filterDistributorGroup"
          :items="distributorList"
          label="Постачальник"
          clearable
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          ></v-select>
        </v-col>
        
        <v-col
          cols="6"
          md="2"
          class="text-center"
        >
          <v-btn color="primary" @click="getReportTarifsPays()"
          >
          
            Отримати
          </v-btn>
        </v-col>
      </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="11" xl="10">
        <v-data-table
          ref="tableTarifs"
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          hide-default-footer
          :items-per-page="-1"
          :items="tarifsPays"
          :options.sync="options"
          :loading="loading"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Оплата по Тарифах</h2></v-toolbar-title>
            
              <v-spacer></v-spacer>
              
              
            </v-toolbar>
            
          </template>
          <template v-slot:group.header="{group, items, isOpen, toggle, headers}">
            <td :colspan="headers.length"  @click="toggle">
              <span v-if="loading">Завантаження...</span>
              <div v-else class="d-flex justify-space-between">
                <span v-if="$vuetify.breakpoint.mdAndUp"></span>
                <span style="vertical-align: middle;">
                  
                    <span class="mr-0 mr-md-3">{{ !loading && group }}</span>
                  
                </span>
                <v-icon right> {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                
                </div>
            </td>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  export default {
    name: "report-tarifs-pays-tv",
    props: ["user"],
    data: () => ({
      menuDate: false,
      updateTable: 0,
      tarifsPays: [],
      tarifsPaysRaw: [],
      filterOperatorGroup: null,
      filterDistributorGroup: null,
      filterDate: new Date().toISOString().substr(0, 7),
      distributorList: [],
      loading: false,
      options: {},
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        { text: "Тариф", value: 'name', sortable: false, },
      ],
    }),
    computed: {
        ...mapGetters({
          operators_groups: 'filters/operators_groups',
          operators: "filters/operators",
        }),
        computedDateFormatted () {
          return this.formatDate(this.filterDate)
        },
    },
    mounted(){
      this.filterOperatorGroup = this.user.operator_id == 1 && 12 || this.user.operator_id
      this.changeDistributorList()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler () {
          this.getReportTarifsPays();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getReportTarifsPays();
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month] = date.split('-')
        return `${this.monthFormat[Number(month-1)]} ${year}`
      },
      getReportTarifsPays(){
				this.loading = true;
				axios.get(`/api/report/get_tarpaymonth/`, {params: {month: this.filterDate.split('-')[1], year: this.filterDate.split('-')[0], operator_group: this.filterOperatorGroup, operator: this.filterDistributorGroup}})
				.then( response =>{
					//console.log(response.data)
					
					this.tarifsPaysRaw = response.data
          this.formateArrData()
				})
				.catch(() => {
					//console.log(error);
					//this.errored = true;
				})
				.finally(() => (this.loading = false)); 
			},
      formateArrData(){
        let tempTarifs = []
        let tempOperators = []
        let tempReport = []
        let tempHeaders = [
          { text: "Тариф", value: 'name', sortable: false, },
        ]

        this.tarifsPaysRaw.forEach( element => {
          if(tempTarifs.includes(element.tarif)){
            //
          }else {
            tempTarifs.push(element.tarif)
            tempReport.push({id: element.tarif, name: element.tarif_name})
          }
          
          if(tempOperators.includes(element.operator)){
            //
          }else {
            tempOperators.push(element.operator)
            tempHeaders.push({ text: 'Кількість', value: String(element.operator +'.count_user'), cellClass: 'text-end', class: 'text-end', sortable: false, },)
            tempHeaders.push({ text: element.operator_name, value: String(element.operator +'.amount'), cellClass: 'text-end', class: 'text-end', sortable: false, },)
          }
          
          tempReport.find(x => x.id == element.tarif)[element.operator] = element
        });
        console.log(tempReport)
        this.tarifsPays = tempReport
        this.headers = tempHeaders
        //console.log(this.headers)
        
      },
      changeDistributorList(){
        this.filterDistributorGroup = null
        if(!this.filterOperatorGroup) {
          return
        }
        let distributor = this.operators_groups.find(x => x.id == this.filterOperatorGroup)?.distributor
        this.distributorList = distributor.filter(x => x.tv)
      }
      
    
    },
  }
</script>