var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3 mb-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","clearable":"","dense":"","outlined":"","hide-details":"","label":"Дата зміни тарифу"},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"selected-items-text":" ","locale":"uk-UA"},on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","md":"2"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getReportTarifsPlanJob()}}},[_vm._v(" Отримати ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"10"}},[_c('v-data-table',{ref:"tableTarifs",staticClass:"elevation-1 pa-3",attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема данних для відображення","hide-default-footer":"","items-per-page":-1,"items":_vm.tarifsPlan,"options":_vm.options,"loading":_vm.loading,"sort-by":"date_apply","sort-desc":true,"focusable":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":"","color":_vm.$vuetify.theme.dark ? 'background2' : 'white'}},[_c('v-toolbar-title',[_c('h2',{staticClass:"mr-6"},[_vm._v("Заплановані зміни тарифів")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.is_order",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"x-small":"","color":item.is_order ? 'primary': 'accent'}},[_vm._v(" "+_vm._s(item.is_order ? 'Заявка' : 'Авто')+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"link_cursor",class:_vm.$vuetify.theme.dark ? 'primary--text text--lighten-1' : '',attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openAbonentInfoModal(item.user && item.user)}}},[_vm._v(_vm._s(item.address))])]}}])})],1)],1),_c('keep-alive',[_c('AbonentInfoModal',{ref:"AbonentInfoModal",attrs:{"backTo":"Звіти"},on:{"update-data":function($event){_vm.updateTable += 1}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }