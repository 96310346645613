<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
        <v-col
          cols="6"
          md="3"
        >
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              readonly
              v-bind="attrs"
              v-on="on"

              dense
            outlined
            hide-details
            label="Період"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterDate"
            type="month"
            locale="uk-UA"
            @input="menuDate = false"
          ></v-date-picker>
        </v-menu>
        </v-col>
        
        <v-col
         v-if="user && user.operator_id == 1"
          cols="6"
          md="3"
        >
        <v-select
          
          v-model="filterOperatorGroup"
          :items="operators_groups"
          label="Оператор"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          @input="changeDistributorList"
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
        <v-select
          v-model="filterDistributorGroup"
          :items="distributorList"
          label="Постачальник"
          clearable
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2" lg="1">
          <v-row title="Організації" class="mx-0 px-0 d-flex" justify="center">
            <v-checkbox
              
              v-model="filterIsOrganization"
            ></v-checkbox>
            <v-icon :color="filterIsOrganization ? 'primary': ''">mdi-account-group</v-icon>
            
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="2"
          lg="1"
          class="text-center"
        >
          <v-btn color="primary" :loading="loading" @click="getReportTarifsPays()"
          >
            Отримати
          </v-btn>
        </v-col>
      </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="11">
        <v-data-table
          ref="tableTarifs"
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          hide-default-footer
          :items-per-page="-1"
          :items="tarifsPays"
          :options.sync="options"
          :loading="loading"
          class="elevation-1 pa-3 table-report-tarif-pays-inet"
          :class="$vuetify.theme.dark ? 'table-dark-custom' : 'table-light-custom'"
          focusable
          dense
        >
          <template v-slot:top>
            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Оплата по Тарифах</h2></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          
            
          <template v-slot:body.prepend>
              <tr v-if="tarifsPays && tarifsPays.length > 0 && !$vuetify.breakpoint.mobile">
                <td colspan="2" class="custom-table">
                  <span></span>
                </td>
                <td colspan="4" class="custom-table text-center text-color-custom" >
                  <span>Сплатили</span>
                </td>
                <td colspan="5" class="text-center text-color-custom">
                  <span>Надано послуг</span>
                </td>
              </tr>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->
        
          <template v-for="(el, index) in headers" v-slot:[`item.${el.value}`]="{value}">
            <span :key="index" :class="{'error--text': value < 0, 'grey--text': value == 0}">{{ value }}</span>
          </template>
          <template v-slot:item.name="{item, value}">
            <span >{{ value }}</span> <v-icon v-if="item.service_type && item.service_type != 1" style="vertical-align: sub;" color="error" class="ml-2">mdi-information-outline</v-icon>
          </template>
        
        
        
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  export default {
    name: "report-tarifs-pays-inet",
    props: ["user"],
    data: () => ({
      menuDate: false,
      updateTable: 0,
      tarifsPays: [],
      filterIsOrganization: true,
      filterOperatorGroup: null,
      filterDistributorGroup: null,
      filterDate: new Date().toISOString().substr(0, 7),
      distributorList: [],
      loading: false,
      options: {},
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      yourColumnName: 'count',
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        { text: "Тариф", value: 'name', sortable: false, },
        { text: "Кількість абонентів", value: 'count', sortable: false, cellClass: 'text-end custom-table fix-size-column ', class: 'text-end custom-table' },
        { text: "Кількість", value: 'pay_count', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "% від кількості абонентів", value: 'pay_percent', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Сума", value: 'pay_sum', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "% від загальної суми", value: 'pay_persent_sum', sortable: false, cellClass: 'text-end custom-table fix-size-column ', class: 'text-end custom-table' },
        { text: "Кількість", value: 'services_count', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "% від кількості абонентів", value: 'services_percent', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Абонплата без враxування знижки", value: 'services_fee', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "Сума", value: 'services_fee_sum', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
        { text: "% від загальної суми", value: 'services_persent_sum', sortable: false, cellClass: 'text-end fix-size-column ', class: 'text-end' },
       

      ],
    }),
    computed: {
        ...mapGetters({
          operators_groups: 'filters/operators_groups',
          operators: "filters/operators",
        }),
        computedDateFormatted () {
          return this.formatDate(this.filterDate)
        },
    },
    mounted(){
      this.filterOperatorGroup = this.user.operator_id == 1 && 12 || this.user.operator_id
      this.changeDistributorList()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler () {
          //this.getReportTarifsPays();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getReportTarifsPays();
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month] = date.split('-')
        return `${this.monthFormat[Number(month-1)]} ${year}`
      },
      getReportTarifsPays(){
				this.loading = true;
				
        axios({
          method: "post",
          url: this.$router.app.devUrlBillingAPI,
          data: {req_cmd: "reportTarifPays", month: this.filterDate.split('-')[1], year: this.filterDate.split('-')[0], operator_group: this.filterOperatorGroup, operator: this.filterDistributorGroup, is_organization: this.filterIsOrganization},
        })
          .then(response => {
              if(response.data.resp_status == "OK"){
                this.tarifsPays = response.data.resp_result.result
              }else if(response.data.resp_errmsg){
                this.tarifsPays = []
                this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg);
              }else this.tarifsPays = []
          })
          .catch(err => { this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err) })
          .finally(() => (this.loading = false))
			},
      changeDistributorList(){
        this.filterDistributorGroup = null
        if(!this.filterOperatorGroup) {
          return
        }
        let distributor = this.operators_groups.find(x => x.id == this.filterOperatorGroup)?.distributor
        this.distributorList = distributor.filter(x => x.inet)
        this.filterDistributorGroup = this.distributorList[0] && this.distributorList[0].id || null
      }
      
    
    },
  }
</script>

<style>
.table-light-custom td.custom-table {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.table-dark-custom td.custom-table  {
  border-right: thin solid rgba(238, 238, 238, 0.12);
}
.table-light-custom td.text-color-custom{
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.table-dark-custom td.text-color-custom{
  color: rgba(238, 238, 238, 0.7);
  font-weight: bold;
}
td.fix-size-column:not(.v-data-table__mobile-row) {
  width: 120px
}
.table-report-tarif-pays-inet tr:last-child:not(.v-data-table__empty-wrapper){
  font-weight: 800;
}
.table-dark-custom tr:last-child:not(.v-data-table__empty-wrapper) td{
  /*border-top: 1px solid var(--v-error-base);*/
  background: #616161;
}
.table-light-custom tr:last-child:not(.v-data-table__empty-wrapper) td{
  /*border-top: 1px solid var(--v-error-base);*/
  background: #dedede
}



</style>