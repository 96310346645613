<template>
    <div class="reports">
        <v-navigation-drawer
        class=""
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUp"
        
        app
        left
        >
        <template v-slot:prepend>
            <v-toolbar  flat color="secondary lighten-1" dark> 
                
                <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''">
                    <h2 class="accent--text">Звіти</h2>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="drawer = false">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            
            </v-toolbar>

        </template>

            <v-list shaped dense >
                
                <v-list-item-group  v-model="item" color="primary">
                    <template v-for="(item, i) in items">
                        <v-subheader v-if="item.subheader" :key="i">{{ item.title }}:</v-subheader>
                        <v-list-item v-else
                        :class="{'d-none' : (item.checkPermision && !isAdminOrBuh) && (item.onlyAltair && user.operator_id != 1) }"
                        :id="item.component"
                        
                        :key="i+1000"
                        @click="is_component = item.component"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
                
                
            </v-list>
        </v-navigation-drawer>
        
        <v-container fluid class="mt-0">
            <v-row align="center" justify="center">
                <transition name="page" mode="out-in">    
                    <component :user="user" :is="is_component"></component>
                </transition>
            </v-row>
        </v-container>
    
        <v-btn
        v-if="!drawer"
            bottom
            color="primary "
            dark
            fab
            fixed
            left
            @click="drawer = !drawer"
            >
            <v-app-bar-nav-icon />
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import "@/components/Reports/"


export default {
    name: 'reports-page',
    data: () => ({
        is_component: "",  
        drawer: null,
        item: '',
        menu: '',
        items: [
            {subheader: true, title: "Телебачення"},
            { title: 'Абоненти', icon: 'mdi-account-multiple', component:"TarifsAbonentsTV" },
            { title: 'Зміна тарифів', icon: 'mdi-swap-horizontal', component:"TarifsPlanApplyTV" },
            { title: 'Оплата по тарифах', icon: 'mdi-credit-card-outline', component: 'TarifsPaysTV', checkPermision:true },
            {subheader: true, title: "Інтернет"},
            { title: 'Дільниці', icon: 'mdi-home-map-marker', component:"DistrictsBilling" }, //method: reportDistricts, arg: month, year ()
            { title: 'Історія IP адреси', icon: 'mdi-home-map-marker', component:"HistoryIPBilling", checkPermision:true, onlyAltair: true, },
            { title: 'Оплата по тарифах', icon: 'mdi-credit-card-outline', component: 'TarifsPaysInet', checkPermision:true },
            { title: 'Фінансовий звіт', icon: 'mdi-credit-card-outline', component: 'FinanceInet', checkPermision:true },
        ],
    }),
    components: {
        //
    },
    computed: {
        ...mapGetters({
        user: "auth/user",
        users_type: "users/users_type",
        isAdminOrBuh: "auth/isAdminOrBuh"
        }),
    },
    mounted() {
        this.$store.dispatch('auth/fetchUser');
    },
}
</script>