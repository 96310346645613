<template>
  
    <v-container fluid>
      <v-row class="mt-3 mb-2" justify="center">
          <v-col
            cols="6"
            md="3"
          >
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filterDate"
                readonly
                v-bind="attrs"
                v-on="on"
              clearable
                dense
              outlined
              hide-details
              label="Дата зміни тарифу"
              ></v-text-field>
            </template>
            <v-date-picker
              selected-items-text=" "
              v-model="filterDate"
              locale="uk-UA"
              @input="menuDate = false"
              
            ></v-date-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="6"
            md="2"
            class="text-center"
          >
            <v-btn :loading="loading" color="primary" @click="getReportTarifsPlanJob()"
            >
            
              Отримати
            </v-btn>
          </v-col>
        </v-row>
      <v-row justify="center" class="">
        <v-col  md="12" lg="12" xl="10">
        <v-data-table
          ref="tableTarifs"
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          hide-default-footer
          :items-per-page="-1"
          :items="tarifsPlan"
          :options.sync="options"
          :loading="loading"
          sort-by="date_apply"
          :sort-desc="true"
          class="elevation-1 pa-3"
          focusable
        >
          <template v-slot:top>

            <v-toolbar dense flat :color="$vuetify.theme.dark ? 'background2' : 'white'">
              <v-toolbar-title><h2 class="mr-6">Заплановані зміни тарифів</h2></v-toolbar-title>
              <v-spacer></v-spacer>
              
            </v-toolbar>
            
          </template>
          

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.is_order="{ item }">
          
        <v-chip 
          x-small
          class="white--text"
          :color="item.is_order ? 'primary': 'accent'"
        >
         {{ item.is_order ? 'Заявка' : 'Авто' }}
        </v-chip>
      </template>
      <template #item.address="{ item }">
        <div href="#"   class="link_cursor" :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item.user && item.user)">{{ item.address }}</div>
      </template> 
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        </v-data-table>
      </v-col>
        
      </v-row>
      <keep-alive>
        <AbonentInfoModal ref="AbonentInfoModal" backTo="Звіти" v-on:update-data="updateTable += 1"/>
      </keep-alive>
    </v-container>
  </template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'
  export default {
    name: "report-tarifs-plan-tv",
    props: ["user"],
    components:{
      AbonentInfoModal
    },
    data: () => ({
      menuDate: false,
      tarifsPlan: [],
      filterDate: null,
      loading: false,
      options: {},
      updateTable: 0,
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        { text: "Дата", value: 'date_apply' },
        { text: "Тип", value: 'is_order', sortable: false, },
        { text: "Адреса", value: 'address', sortable: false, },
        { text: "Оператор", value: 'operator_group',  },
        { text: "Баланс", value: 'balance', sortable: false, cellClass: 'text-end'},
        { text: "Поточний", value: 'curent_tarif'},
        { text: "Наступний ТБ", value: 'next_tarif'},
        { text: "Наступний Інтернет", value: 'next_inet'},
      ],
    }),
    computed: {
        ...mapGetters({
            //
        }),
    },
    mounted(){
      this.getReportTarifsPlanJob()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler () {
          //this.getReportTarifsPlanJob();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getReportTarifsPlanJob();
      },
      filterDate(){
        this.getReportTarifsPlanJob()
      }
    },
    methods: {
      openAbonentInfoModal(paramsUrlId){
      this.onClickRowStop = true
      this.$refs.AbonentInfoModal.paramsUrlId = paramsUrlId
      this.$refs.AbonentInfoModal.dialog = true
    },
      getReportTarifsPlanJob(){
        this.loading = true;
        axios.get(`/api/report/get_wait/`, {params: {date_apply: this.filterDate}})
        .then( response =>{
          console.log(response.data)
          this.tarifsPlan = response.data
          this.getReportTarifsPlan()
        })
        .catch((err) => {
          //console.log(error);
          //this.errored = true;
          this.$router.app.$sheet.show("Помилка!", err);
        })
        .finally(() => (this.loading = false)); 
      }, 
      getReportTarifsPlan(){
        this.loading = true;
        axios.get(`/api/report/get_sheduled/`, {params: {date_apply: this.filterDate}})
        .then( response =>{
          console.log(response.data)
          this.tarifsPlan.push(...response.data)
        })
        .catch((err) => {
          //console.log(error);
          //this.errored = true;
          this.$router.app.$sheet.show("Помилка!", err);
        })
        .finally(() => (this.loading = false)); 
      }, 
    },
  }
</script>

<style scoped>
.link_cursor {
    cursor: pointer !important
  }
</style>