<template>
  <div id="abonent-info-modal"  justify="center" >
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="true"
      hide-overlay
      
    >
      <v-card id="abonent-info-modal-card" :style="{'background' : $vuetify.theme.themes[theme].background}">
        <v-app-bar
        dense
        fixed
          dark
          color="secondary"
        >
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ backTo }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        
        
       <keep-alive>
        <component style="padding-top: 54px;" :is="is_component" is_modal=1 v-on:dialog-close="dialog=false"/>
       </keep-alive>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

    
    import AbonentInfo from '@/views/AbonentInfo.vue'
	export default {
    components:{
        AbonentInfo
    },
    name: 'abonent-info-modal',
    props: ["backTo"],
		data: () => ({
        dialog: false,
        paramsUrlId: null,
        is_component: ''
    }),
    computed: {
        theme(){
          return this.$vuetify.theme.dark && 'dark' || 'light'
        }
    },
    
    watch:{
        dialog(){
            if (this.dialog){
                this.$route.params.id = this.paramsUrlId
                this.is_component = 'AbonentInfo'
            }else{
              this.$emit('update-data')
              this.is_component = ''
            }
            //console.log(this.$route.params.id)
        }
        
    },
    methods: {
        
        closeDialog(){
            
            this.dialog = false
            this.paramsUrlId =  null,
            this.is_component = ''
        },
        
    
    }
    }
</script>
<style>
.abonent-info-modal-card {
    /*background: rgb(243, 243, 243) !important;*/
}
</style>