<template>
  
  <v-container fluid>
    <v-row class="mt-3 mb-2" justify="center">
        <v-col
          cols="6"
          md="3"
        >
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              readonly
              v-bind="attrs"
              v-on="on"

              dense
            outlined
            hide-details
            label="Період"
            ></v-text-field>
          </template>
          <v-date-picker
            selected-items-text=" "
            v-model="filterDate"
            locale="uk-UA"
            @input="menuDate = false"
          ></v-date-picker>
        </v-menu>
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
        <v-menu
          v-model="menuDate2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate2"
              readonly
              v-bind="attrs"
              v-on="on"

              dense
            outlined
            hide-details
            label="Період"
            ></v-text-field>
          </template>
          <v-date-picker
            selected-items-text=" "
            v-model="filterDate2"
            locale="uk-UA"
            @input="menuDate2 = false"
          ></v-date-picker>
        </v-menu>
        </v-col>
        
        <v-col
        v-if="user && user.operator_id == 1"
          cols="6"
          md="3"
        >
        <v-select
        
          v-model="filterOperatorGroup"
          :items="operators_groups"
          label="Оператор"
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          @input="changeDistributorList"
          ></v-select>
        </v-col>
        <v-col
        v-else
          cols="6"
          md="3"
        >
        <v-select
          v-model="filterDistributorGroup"
          :items="distributorList"
          label="Постачальник"
          clearable
          dense
          outlined
          hide-details
          item-text="name"
          item-value="id"
          ></v-select>
        </v-col>
        
        <v-col
          cols="6"
          md="2"
          class="text-center"
          
        >
          <v-btn :loading="loading" color="primary" @click="getReportTarifsAbonents()"
          >
          
            Отримати
          </v-btn>
        </v-col>
      </v-row>
    <v-row justify="center" class="">
      <v-col  md="12" lg="10" xl="8">
        <ChartTarifsAbonents :chartData="chartData" />
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
function firstDay(){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() - 1;
    return new Date(year, month, 1).toDateInputValue()
}
  import axios from "axios"
  import { mapGetters } from "vuex"
  import ChartTarifsAbonents from '@/components/Reports/Tools/ChartTarifsAbonents.vue'
  export default {
    name: "report-tarifs-abonents-tv",
    props: ["user"],
    components: {
     ChartTarifsAbonents
    },
    data: () => ({
      menuDate: false,
      menuDate2: false,
      tarifsAbonents: [],
      filterOperatorGroup: null,
      filterDistributorGroup: null,
      filterDate: firstDay(),
      filterDate2: new Date().toISOString().substr(0, 10),
      distributorList: [],
      loading: false,
      options: {},
      monthFormat: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
      //colorsCharts: ["#ffb400", "#d2980d", "#a57c1b", "#786028", "#363445", "#48446e", "#5e569b", "#776bcd", "#9080ff"],
      colorsCharts:['#007cb5','#5b7cc9','#9b77ce','#d46cbf','#fe64a0','#ff6c76','#ff8547','#ffa600','#2a476b','#424470','#5c3f6f','#743866','#863256','#913041','#933729','#8c440b',],
      chartData: {},
      /*chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#65769d',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          },
          {
            label: 'Data Two',
            backgroundColor: '#eee',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          },
          {
            label: 'Data tree',
            backgroundColor: '#555',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      },*/
    }),
    computed: {
        ...mapGetters({
          operators_groups: 'filters/operators_groups',
          operators: "filters/operators",
        }),
        computedDateFormatted () {
          return this.formatDate(this.filterDate)
        },
    },
    mounted(){
      this.filterOperatorGroup = this.user.operator_id == 1 && 12 || this.user.operator_id
      this.changeDistributorList()
      this.getReportTarifsAbonents()
    },
    activated(){
      //
    },
    watch: {
      options: {
        handler () {
          this.getReportTarifsAbonents();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getReportTarifsAbonents();
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month] = date.split('-')
        return `${this.monthFormat[Number(month-1)]} ${year}`
      },
      getReportTarifsAbonents(){
				this.loading = true;
				axios.get(`/api/report/get_usertarday/`, {params: {start_date: this.filterDate, end_date: this.filterDate2, operator_group: this.filterOperatorGroup, operator: this.filterDistributorGroup}})
				.then( response =>{
					console.log(response.data)
          this.createChartData(response.data)
				})
				.catch((err) => {
					//console.log(error);
					//this.errored = true;
          this.$router.app.$sheet.show("Помилка!", err);
				})
				.finally(() => (this.loading = false)); 
			},
      changeDistributorList(){
        this.filterDistributorGroup = null
        if(!this.filterOperatorGroup) {
          return
        }
        let distributor = this.operators_groups.find(x => x.id == this.filterOperatorGroup)?.distributor
        this.distributorList = distributor.filter(x => x.tv)
      },
      createChartData(data){
        let labels = []
        let tarifs = []
        let datasets = []
        data.forEach(element => {
            if(labels.includes(element.date)) {
              //
            }
            else{
              labels.push(element.date)
            }
              
            if(tarifs.includes(element.tarif)){
              datasets.find(x => x.id_tarif == element.tarif).data.push(element.amount)
            }else{
              tarifs.push(element.tarif)
              datasets.push({
                label: `Тариф: ${element.tarif_name}`,
                id_tarif: element.tarif,
                backgroundColor: this.colorsCharts[tarifs.indexOf(element.tarif)],
                data: [element.amount],
                fill: false,
                borderColor: this.colorsCharts[tarifs.indexOf(element.tarif)],
                pointBorderWidth: 5,
                pointHitRadius: 5,
                pointHoverBorderWidth: 5,
                tension: 0.1,
              })
            }
        });
        this.chartData = {labels: labels, datasets: datasets}
      }
    },
  }
</script>